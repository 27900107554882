import React, { ReactNode } from "react";

import styles from "./b2c-telia-assistive-text.module.scss";

type Props = {
  children: ReactNode;
  className?: string;
  dataTestId?: string;
  id?: string;
};
export const TeliaAssistiveText = ({ className, children, dataTestId, id }: Props) => {
  const classname = className
    ? `${styles["b2x-telia-assistive-text"]} ${className}`
    : styles["b2x-telia-assistive-text"];

  return (
    <div className={classname} data-testid={dataTestId} id={id}>
      {children}
    </div>
  );
};
